import React from "react"

import { Badge, Stack, Text } from "@kiwicom/orbit-components"

import Container from "components/container"
import { EmberInfoBox, InfoRow } from "components/generic/ember-card"
import { ProfileLayout } from "components/layout-custom"

import { getPersonName } from "utils/name-utils"
import { useGlobalState } from "utils/state-utils"

const Profile = () => {
  const { loggedIn, groups, profile } = useGlobalState()

  return (
    <ProfileLayout title="Profile">
      <Container>
        <Stack spacing="XLarge">
          <EmberInfoBox sectionTitle="Profile">
            <InfoRow label="Name">{getPersonName(profile) ?? "None"}</InfoRow>
            <InfoRow label="Email">{profile?.email ?? "None"}</InfoRow>
            <InfoRow label="Postcode">{profile?.postcode ?? "None"}</InfoRow>
          </EmberInfoBox>
          <EmberInfoBox sectionTitle="Login Details">
            <InfoRow label="Logged Status">
              {loggedIn ? "Logged In" : "Not Logged In"}
            </InfoRow>
            <InfoRow label="Groups">
              <div>
                {groups?.length > 0
                  ? groups.map((group) => {
                      return (
                        <Badge key={group} type="infoSubtle">
                          {group}
                        </Badge>
                      )
                    })
                  : "None"}
              </div>
            </InfoRow>
          </EmberInfoBox>
          <EmberInfoBox sectionTitle="Password">
            <Text>
              Coming soon: Change your password or set up a new password
            </Text>
          </EmberInfoBox>
        </Stack>
      </Container>
    </ProfileLayout>
  )
}

export default Profile
